<template>
  <div>
    <login-com :type="returnType" v-if="forceUpdate"></login-com>
  </div>
</template>

<script>
  import LoginCom from '@/components/LoginCom.vue'

  export default {
    name: "login",
    data() {
      return {
        returnType: 0,
        forceUpdate:true,
      }
    },
   async mounted() {


    },
    components: {
      LoginCom
    },
    watch: {
      $route: {
        immediate: true, // 一旦监听到路由的变化立即执行
        handler(to, from) {
          if(this.$route.query.code){
            this.forceUpdate = false;
            this.$nextTick(()=> {
              this.forceUpdate = true;
            })
            this.returnType = 5
          }else{
            if (to.name === 'login') {
              if (JSON.stringify(to.params) !== '{}') {
                this.returnType = to.params.type;
              } else {
                this.returnType = 1;
              }
            }
          }
        },
      }
    },
    methods: {}
  }
</script>

<style scoped>
</style>
